/**
 * Copyright 2019 Schwäbische Werkzeugmaschinen GmbH
 */

import '@polymer/iron-icons/iron-icons';
import '@polymer/paper-fab/paper-fab';
import { css, html, LitElement, property, state } from 'lit-element';
import { TemplateResult }                         from 'lit-html';
import { connect }                                from 'pwa-helpers/connect-mixin';
import { BasicRouter, Router }                    from '../../../../../src/js/services/routingConfig';
import { isUserSwEmployee }                       from '../../../../js/LdGlobal';
import { BrowserFileProvider }                    from '../../../../js/services/FileProvider';
import { BrowserFormValidator }                   from '../../../../js/services/Validator';
import { LdTranslate }                            from '../../../ld-application/i18n/ld-i18next';
import { LdBreadCrumb }                           from '../../../ld-application/ld-breadcrumb';
import { emptyAssets }                            from '../../../ld-redux/actions/ld-assets-actions';
import { store }                                  from '../../../ld-redux/ld-redux-store';

import './ld-download-upload';
import { LdSettingsViewStyles }                   from './styles';

/**
 * @extends LitElement
 */
export class LdSettingsView extends connect(store)(LitElement) {
  // --------------------------------------------------------------------------------------
  fileProvider                                                                   = new BrowserFileProvider();
  formValidator                                                                  = new BrowserFormValidator();
  @property({ type: Object, hasChanged: () => true }) _subModules: Array<Object> = [];
  @property({ type: Object, hasChanged: () => true }) _userInformation: Object   = {};
  @property({ type: Object, hasChanged: () => true }) _tabs: Array<Object>       = [
    {
      key: 'limit',
      title: LdTranslate('modules.limit.tabs.list'),
      link: 'settings/limit/list',
    },
    {
      key: 'alarms',
      title: LdTranslate('modules.limit.tabs.alarms'),
      link: 'settings/alarms/list',
    },
  ];
  @property({ type: Object }) _innerTabsLimits: Array<Object> = [
    {
      key: '?machine',
      title: LdTranslate(`modules.limit.tabs.machine`),
      link: '/settings/limit/list?machine',
    },
    {
      key: '?type',
      title: LdTranslate('modules.limit.tabs.type'),
      link: '/settings/limit/list?type',
    },
  ];
  @property({ type: Object }) _innerTabsAlarms: Array<Object> = [
    {
      key: '?machine',
      title: LdTranslate(`modules.alarms.tabs.machine`),
      link: '/settings/alarms/list?machine',
    },
    {
      key: '?type',
      title: LdTranslate('modules.alarms.tabs.type'),
      link: '/settings/alarms/list?type',
    },
  ];
  @state() private router: Router                                                = new BasicRouter();

  // --------------------------------------------------------------------------------------
  static get styles() {
    return [LdSettingsViewStyles, css`
      :host {
        display: flex;
        flex-direction: column;
        flex: auto;
      }

      .container {
        flex: auto;
        display: flex;
        flex-direction: column;
        margin-right: 50px;
      }

      .grid-container {
        display: flex;
        flex-direction: column;
        flex: auto;
      }

      ld-assets-header {
        margin: 20px 0;
      }`];
  }

  /**
   * @returns void
   */
  // --------------------------------------------------------------------------------------
  connectedCallback() {
    super.connectedCallback();
    store.dispatch(emptyAssets());
  }


  /**
   * to prevent memory leaks
   * @returns void
   */
  // --------------------------------------------------------------------------------------
  disconnectedCallback() {
    super.disconnectedCallback();
    LdBreadCrumb.setBreadcrumb({
      assetType: null,
      assetID: null,
      backButtonLink: null,
    });
  }


  // --------------------------------------------------------------------------------------
  render(): TemplateResult {
    let _contentRenderViews = [];

    if (this._userInformation != null) {
      if (!isUserSwEmployee(this._userInformation)) {
        this.router.changeView(`/dashboard`, { useSpinner: true });
      }
    }

    _contentRenderViews.push(html`
      <ld-tabs .selected='${this._subModules[1]}' .tabs='${this._tabs}' fallbackSelection='limit'></ld-tabs>
    `);

    switch (this._subModules[1]) {
      case 'limit':
        _contentRenderViews.push(html`
          <ld-settings-list
            .fileProvider='${this.fileProvider}'
            .formValidator='${this.formValidator}'
            ._subModules='${this._subModules}'
            ._userInformation='${this._userInformation}'
            selectedTab='limit'
            ._innerTabs='${this._innerTabsLimits}'>>
          </ld-settings-list>`);
        break;
      case 'alarms':
        _contentRenderViews.push(html`
          <ld-settings-list
            .fileProvider='${this.fileProvider}'
            .formValidator='${this.formValidator}'
            ._subModules='${this._subModules}'
            ._userInformation='${this._userInformation}'
            selectedTab='alarms'
            ._innerTabs='${this._innerTabsAlarms}'>
          </ld-settings-list>`);
        break;
    }

    return html`
      <div class='container'>
        <div class='grid-container '>
          ${_contentRenderViews}
        </div>
      </div>
    `;

  }

  // --------------------------------------------------------------------------------------

  stateChanged(state: any) {
    this._userInformation = state.LdUsersReducer.info;
  }
}

customElements.define('ld-settings', LdSettingsView as unknown as CustomElementConstructor);
